<template>
  <div class="issue-status">
    <v-toolbar flat>
      <v-toolbar-title>Estatus Incidencias</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" rounded color="primary" :to="{name: 'issue-status-new'}">Nuevo</v-btn>
    </v-toolbar>

    <v-row class="mr-5">
      <v-spacer></v-spacer>
      <v-col justify="end" md="4">
        <v-text-field
          v-model="search"
          class="ml-5"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      flat
      :search="search"
      :headers="headers"
      :items="getIssueStatusIssueStatuses"
      :loading="getIssueStatusIsLoading"
      height="536px"
      class="px-4"
    >
      <template v-slot:item.name="{ item }">
        <v-chip dark :color="item.color">{{item.name}}</v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-row justify="end">
          <v-btn
            small
            rounded
            outlined
            color="primary"
            :to="{name: 'issue-status-edit', params: { issueStatusId: item.id }}"
          >Editar</v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { to } from '@/helpers'
import { ISSUE_STATUS_GET_ISSUE_STATUSES } from '@/store/actions.type'
import { mapGetters } from 'vuex'

export default {
  name: 'IssueStatus',
  props: {},
  mounted() {
    this.getIssueStatus()
  },
  data() {
    return {
      isLoading: true,
      error: null,
      search: '',
      IssueStatus: [],
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Descripción', value: 'description' },
        { text: 'Tipo', value: 'statusType.name' },
        { text: '', value: 'actions' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getIssueStatusIssueStatuses', 'getIssueStatusIsLoading'])
  },
  methods: {
    async getIssueStatus() {
      const [err] = await to(
        this.$store.dispatch(ISSUE_STATUS_GET_ISSUE_STATUSES)
      )
      if (err) {
        this.error = err
        this.isLoading = false
      } else {
        this.IssueStatus = this.getIssueStatusIssueStatuses
        this.isLoading = false
      }
    }
  },
  components: {}
}
</script>
